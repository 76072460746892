import { Controller } from "@hotwired/stimulus"
import { createAlert } from "../scripts/createAlert"

export default class extends Controller {
  static targets = []
  static values = {}

  intervals = {};

  successMessages = {
    "HistoricalTrendJob": "Trend analysis completed successfully.",
    "RecapJob": "Recap generated successfully.",
    "BioJob": "Bio generated successfully.",
    "BioBatchJob": "Bios generated successfully."
  }

  errorMessages = {
    "HistoricalTrendJob": "Error when doing trend analysis.",
    "RecapJob": "Error while generating recap.",
    "BioJob": "Error while generating bio.",
    "BioBatchJob": "Error while generating bios."
  }

  connect() {
    // console.log("Hello, Stimulus from delayed-job-watcher!", this.element)

    const watching_jobs = JSON.parse(localStorage.getItem('delayed_jobs')) || [];

    const params = new URLSearchParams(window.location.search);
    const job_id = params.get('job_id');
    if (job_id) {
      watching_jobs.push(job_id);
      localStorage.setItem('delayed_jobs', JSON.stringify(watching_jobs));
    }

    if (watching_jobs.length > 0) {
      this.startJobWatchingIntervals(watching_jobs);
    }
  }

  startJobWatchingIntervals(job_ids) {

    job_ids.forEach(async (job_id) => {

      // check to see if it actually needs to be watched
      // if we get a status === 'not_found' then we can remove it from the list
      const data = await this.makeRequest(job_id);
      if (data.status === 'not_found') {
        console.log(`Job ${job_id} does not exist, removing from list`);

        // remove it from localStorage
        this.removeJobIdFromLocalStorage(job_id);
        this.removeJobIdFromUrl();

        return;
      }

      const interval = setInterval(() => {
        this.fetchSingleJobStatus(job_id);
      }, 5000);

      this.intervals[job_id] = interval;
    });

  }

  async fetchSingleJobStatus(job_id) {

    const data = await this.makeRequest(job_id);

    if (data.status === 'completed' || data.status === 'failed') {
      // remove it from the interval
      if (this.intervals[job_id]) {
        clearInterval(this.intervals[job_id]);
        this.intervals[job_id] = null
      }

      // remove it from localStorage
      this.removeJobIdFromLocalStorage(job_id);

      // remove it from the URL fragment
      this.removeJobIdFromUrl();

      const isReloading = `${window.location.pathname}${window.location.search}` === data.trackable.redirect_route;

      // the job is done
      let message, immediate_type, cookie_type;
      if (data.status === 'completed') {
        message = this.buildSuccessMessage(data.trackable, isReloading);
        immediate_type = 'info';
        cookie_type = 'notice';
      } else {
        message = this.buildErrorMessage(data.trackable, isReloading);
        immediate_type = 'danger';
        cookie_type = 'danger';
      }

      // if they are the same page that is the redirect, just reload the page for them
      if (isReloading) {
        // set flash to present after the page reload
        this.setFlashMessageAsCookie(message, cookie_type);

        // reload the page
        window.location.reload();
      } else {
        createAlert(immediate_type, message, true);
      }

    }

    if ((data.status === 'pending' || data.status === 'processing') && data.trackable && data.trackable.updated_at) {
      // check for long running jobs and cancel them
      const updated_at = new Date(data.trackable.updated_at);
      const now = new Date();
      const diff = now - updated_at;
      const diffInMinutes = Math.floor(diff / 1000 / 60);
      if (diffInMinutes > 10) {
        console.log(`Job ${job_id} has been running for more than 10 minutes, cancelling it`);

        // remove it from the interval
        if (this.intervals[job_id]) {
          clearInterval(this.intervals[job_id]);
          this.intervals[job_id] = null;
        }

        // remove it from localStorage
        this.removeJobIdFromLocalStorage(job_id);

        // remove it from the URL fragment
        this.removeJobIdFromUrl();
      }
    }

  }

  makeRequest(job_id) {
    return fetch(`/jobs/${job_id}`).then(response => response.json()).then(data => {
      return data;
    }).catch(e => {
      console.error('Error:', e);

      // console.error('Error:', error);
      createAlert('danger', 'An error occurred while fetching the job status.', true);

      // remove it from the interval
      this.intervals[job_id] && clearInterval(this.intervals[job_id]);

      return { error: e.message };
    });
  }

  removeJobIdFromLocalStorage(job_id) {
    const watching_jobs = JSON.parse(localStorage.getItem('delayed_jobs'));
    const index = watching_jobs.indexOf(job_id);
    watching_jobs.splice(index, 1);
    localStorage.setItem('delayed_jobs', JSON.stringify(watching_jobs));
  }

  removeJobIdFromUrl() {
    // remove from the URL fragment
    const urlObj = new URL(window.location);

    // Access the search parameters
    const params = urlObj.searchParams;

    // Remove the key
    params.delete('job_id');

    // Return the updated URL as a string
    window.history.replaceState(null, '', urlObj.toString());
  }

  buildSuccessMessage(trackable, isReloading) {
    let message = trackable && this.successMessages[trackable.job_type] || 'Job completed successfully.';
    if (!isReloading && trackable.redirect_route) {
      message += ` <a href="${trackable.redirect_route}">View</a>`;
    }

    return message;
  }

  buildErrorMessage(trackable, isReloading) {
    let message = trackable && this.errorMessages[trackable.job_type] || 'Job has failed.';
    if (!isReloading && trackable.redirect_route) {
      message += ` <a href="${trackable.redirect_route}">View</a>`;
    }

    return message;
  }

  setFlashMessageAsCookie(message, type = 'info') {
    document.cookie = `flash_${type}=${message}; path=/`;
  }

}

