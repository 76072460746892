import { Controller } from "@hotwired/stimulus"
import { createAlert } from '../scripts/createAlert';

export default class extends Controller {
  static targets = [ 'input', 'modal', 'foundXmlId', 'idsContainer', 'textInput', 'form' ]
  static values = {
    shouldSubmit: Boolean
  }

  ids = [];

  connect() {
    // console.log("Hello, XML Help Controller from Stimulus!", this.element)
    // console.log(this.inputTarget);
    // console.log(this.containerTarget)
    // console.log(this.addButtonTarget)
  }

  openFinder() {
    this.inputTarget.click();
  }

  findXmlIds() {
    const files = this.inputTarget.files;
    const promises = Array.from(files).map(file => {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = (e) => {
          this.extractIds(reader.result);
          resolve();
        }

        reader.onerror = (e) => {
          reject(e);
        }

        reader.readAsText(file);
      });
    });

    Promise.all(promises).then(() => {
      // console.log('Extracted all of the IDs from the given files', this.ids);
      this.displayIds();
    }).catch(error => {
      console.error('Error reading files', error);
    });

  }

  extractIds(xml) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml,"text/xml");
    const ids = Array.from(xmlDoc.getElementsByTagName("team")).map(team => team['id'].trim());
    // console.log('Found IDS', ids);

    this.ids.push(...ids);
  }

  displayIds() {
    // make a fetch request to the server to get modal body and inject it
    var ids = Array.from(new Set(this.ids));

    // console.log('Unique IDS', ids);

    fetch(`/xml_ids?ids=${ids.join(',')}`).then(res => res.text()).then(partial => {
      this.idsContainerTarget.innerHTML = '';
      this.idsContainerTarget.insertAdjacentHTML('beforeend', partial);

      new bootstrap.Modal(this.modalTarget).show();
    });

  }

  attemptSubmit(event) {
    event.preventDefault();

    if (this.shouldSubmitValue) {

      // if we are auto-submitting the form, we need to find the selected xml ids and add them to the form as a single string
      const selectedIds = Array.from(document.querySelectorAll('input[id^="entity_team_xml_id_"]:checked'))
        .map(checkbox => checkbox.value)
        .join(',');

      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = 'entity_team[xml_id]';
      hiddenField.value = selectedIds;

      this.formTarget.appendChild(hiddenField);

      this.formTarget.submit();

    } else if (this.hasTextInputTarget && this.foundXmlIdTargets.length > 0) {

      // this is when we don't want to auto-submit the form but just update the text field with the selected xml id(s)
      const found_xml_id = this.foundXmlIdTargets.find(target => target.checked).value;
      this.textInputTarget.value = found_xml_id;

      document.querySelector('#dismiss-xml-id-helper-modal').click();

    } else {
      createAlert('warning', 'An unknown error has occurred.');
    }
  }
}

