import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "fileInput" ]

  modal = null;

  connect() {
    // console.log("Hello, recap import Controller here!", this.element)
    // console.log(this.formTarget);

    this.modal = new bootstrap.Modal(document.getElementById('waitForTheMagicModal'));
  }

  openFileSelect() {
    this.fileInputTarget.click();
  }

  submit(event) {
    this.modal.show();

    this.formTarget.submit();
  }


}
