import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { createAlert } from '../scripts/createAlert';

export default class extends Controller {
  static targets = [ "sortable" ]

  connect() {
    // console.log("Hello, Athlete Images Controller here!", this.element)
    // console.log(this.postTargets)
    // console.log(this.addButtonTarget)
    // console.log(this.containerTarget)

    this.sortableTargets.forEach(elm => {
      Sortable.create(elm, {
        draggable: '.athlete-images-sortable',
        dataIdAttr: 'data-sortable-id',
        handle: ".sortable-handle",  // Drag handle selector within list items

        // Changed sorting within list
        onUpdate: function (event) {
          const images = event.target.querySelectorAll('.athlete-image');
          const attachmentIds = Array.from(images).map(elm => elm.dataset.attachmentId)
          const athleteSlug = $(".page").data('athlete-slug');

          const token = $('meta[name="csrf-token"]').attr('content');
          $.ajaxSetup({
            beforeSend: function (xhr) {
              xhr.setRequestHeader('X-CSRF-Token', token);
            }
          });

          $.post(`/athletes/${athleteSlug}/images`, { ordered_image_ids: attachmentIds }).then(() => {
            createAlert('success', 'Image order updated')
          })
        }
      });
    })
  }

}
