import { Controller } from "@hotwired/stimulus"
import { createAlert } from "../scripts/createAlert"
export default class extends Controller {
  static targets = [ "modal", "tagsModal", "promoteButton", "recipientCheckbox", "sendNotificationButton", "viewButton", "notificationRecipients" ]
  static values = {
    template: String,
    recipient: String,
    story: String
  }

  connect() {
    // console.log("Hello, Stories Interview Template Responses Controller here!", this.element)
    // console.log(this.modalTarget);
    // console.log(this.templateValue);
  }

  displayResults({ params }) {
    // console.log('Params', event.params.recipient);
    // console.log('Template value', this.templateValue);
    // console.log('Modal', this.modalTarget);
    // console.log('promoted?', params.promoted);

    if (params.story) {
      this.promoteButtonTarget.classList.remove('d-block');
      this.promoteButtonTarget.classList.add('d-none');

      this.viewButtonTarget.classList.remove('d-none');
      this.viewButtonTarget.classList.add('d-block');

      this.storyValue = params.story;
    } else {
      this.promoteButtonTarget.classList.remove('d-none');
      this.promoteButtonTarget.classList.add('d-block');

      this.viewButtonTarget.classList.remove('d-block');
      this.viewButtonTarget.classList.add('d-none');

      this.storyValue = null;
    }

    fetch(`/interview_templates/${this.templateValue}/recipients/${params.recipient}/responses`).then(res => res.text()).then(partial => {
      this.modalTarget.innerHTML = "";
      this.modalTarget.insertAdjacentHTML( 'beforeend', partial );

      this.recipientValue = params.recipient;
    });
  }

  toggleAudio({ target, params }) {
    const audio = document.getElementById(`audio-${params.id}`);
    audio.addEventListener('ended', changeToPlayAtEnd, { once: true });

    function changeToPlayAtEnd() {
      target.innerHTML = '<i class="fas fa-play fa-fw"></i> Replay Response'
      this.removeEventListener('ended', changeToPlayAtEnd);
    }

    if (audio.paused) {
      audio.play();
      target.innerHTML = '<i class="fas fa-pause fa-fw"></i> Replay Response'
    } else {
      audio.pause();
      target.innerHTML = '<i class="fas fa-play fa-fw"></i> Replay Response'
    }
  }

  promoteToStory() {
    fetch(`/interview_templates/${this.templateValue}/recipients/${this.recipientValue}/promote`, { method: 'post' })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        createAlert('info', 'Story created successfully.');

        this.storyValue = data.story_id;

        this.promoteButtonTarget.classList.add('d-none');

        this.viewButtonTarget.classList.remove('d-none');
        this.viewButtonTarget.classList.add('d-block');
      });
  }

  viewStory() {
    window.location = `/entity_admins/stories_ai/${this.storyValue}`;
  }

  manageTags({ params }) {
    fetch(`/interview_templates/${this.templateValue}/recipients/${params.recipient}/tags`).then(res => res.text()).then(partial => {
      this.tagsModalTarget.innerHTML = "";
      this.tagsModalTarget.insertAdjacentHTML( 'beforeend', partial );

      // this.recipientValue = params.recipient;
    });
  }

}
