import { Controller } from "@hotwired/stimulus"
import { createAlert } from '../scripts/createAlert'

export default class extends Controller {

  connect() {
    // console.log("Hello, from File Upload Size Stimulus Controller!", this.element)
  }

  change(event) {
    const file = event.target.files[0];
    const MAX_SIZE_MB = 50;

    if (file) {
        if (file.size > MAX_SIZE_MB * 1024 * 1024) {
          createAlert('danger', `The file "${file.name}" exceeds the maximum size of ${MAX_SIZE_MB}MB.`);
          event.target.value = '';
        }
    }
  }

}