import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "control", "parser" ]

  connect() {
    // console.log("Hello, Parsers Controller here!", this.element)
    // console.log(this.parserTargets);
  }

  toggleParser(event) {
    const showControl = this.parserTargets.filter(p => p.checked).some(p => p.dataset.idsRequired == "true")
    if (showControl) {
      this.controlTarget.classList.remove("d-none");
      this.controlTarget.classList.add("d-block");
    } else {
      this.controlTarget.classList.remove("d-block");
      this.controlTarget.classList.add("d-none");
    }

  }

}
