// src/controllers/interviewer_controller.js
import { Controller } from "@hotwired/stimulus"
import { createAlert } from '../scripts/createAlert';
import AudioRecorder from 'audio-recorder-polyfill'
import WaveSurfer from 'wavesurfer.js'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'

let recordingWavesurfer, recordedWavesurfer, recorder, playbackInterval;

// for video
let mediaRecorder, stream;

export default class extends Controller {
  static targets = [
    "text", "recordButton", "pauseButton", "resumeButton", "playButton",
    "audioFile", "submitButton",
    "radio", "contentContainer", "lengthContainer", "lengthIcon", "lengthText", "progress", "total",
    "errorContainer", "startVideoRecording", "stopVideoRecording", "noAudioMessageContainer",
    "videoElement", "videoFile", "tabPane",
    "questionAudio", "questionPlay", "questionPause", "waveFormContainer", "recordedAudioContainer",
    "imageInput", "savingModal", "existingVideoElement", "mobileNavigation"
  ]

  static values = {
    interval: Number,
    duration: String,
    bugReportPath: String,
    answerUrl: String,
    questionTargetLength: String
  }

  // error message caught when init'ing audio
  error;

  // track whether the text/audio/video file has changed
  answerHasChanged = false;

  connect() {
    // console.log('Hello from the Interviewer controller!', this.element);
    // console.log('mobile navigation', this.mobileNavigationTargets);
    // console.log('Saving modal', this.savingModalTarget);

    self.error = '';

    this.initAudio();

    // Enable the use of radio buttons to control tabs
    this.radioTargets.forEach((radio) => {
      radio.addEventListener('click', (event) => {
        this.toggleInputDisplay(event);
      });
    });

  }

  toggleInputDisplay(event) {
    this.tabPaneTargets.forEach((tab) => {
      tab.classList.remove('show', 'active');
    });

    let tabId = event.target.id.replace("-radio", "");
    document.getElementById(tabId).classList.add('show', 'active');
  }

  initAudio() {
    //check if browser supports getUserMedia
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording!');
      return;
    }

    // if we have an answer to display, "preview" it
    if (this.answerUrlValue) {
      this.previewAudio();
    }
  }

  textChangeListener(event) {
    this.answerHasChanged = true;
  }

  createWaveSurfer() {
    // Create an instance of WaveSurfer for recording
    if (recordingWavesurfer) {
      recordingWavesurfer.destroy()
    }

    recordingWavesurfer = WaveSurfer.create({
      container: '#waveFormContainer',
      mediaType: 'audio/mp4', // Specify the audio file type,
      backend: 'MediaElement', // add this too
      waveColor: '#0e1a2c',
      progressColor: '#FFD81B',
      // Set a bar width
      barWidth: 2,
      // Optionally, specify the spacing between bars
      barGap: 1,
      // And the bar radius
      barRadius: 2
    })

    // Initialize the Record plugin
    recorder = recordingWavesurfer.registerPlugin(RecordPlugin.create({ mimeType: 'audio/mp4', scrollingWaveform: true, renderRecordedAudio: false, mediaRecorderTimeslice: 1000 }))

    // When paused or ended, render the recorded audio
    // recorder.on('record-end', this.previewAudio.bind(this));
    recorder.on('record-pause', this.previewAudio.bind(this));
    recorder.on('record-progress', this.updateTotalTime.bind(this));
  }

  previewAudio(blob) {
    if (blob && !blob.size) {
      // console.log('Got a null blob, returning');
      return;
    }

    this.recordedAudioContainerTarget.innerHTML = '';

    // update the total time when previewing the audio
    this.progressTarget.classList.remove('d-none');
    this.progressTarget.classList.add('d-block');

    // reset the previous playback timer
    this.progressTarget.textContent = `00:00 /\u00A0`;

    // const container = document.querySelector('#recordings')
    const recordedUrl = blob ? URL.createObjectURL(blob) : this.answerUrlValue;

    // Create wavesurfer from the recorded audio
    recordedWavesurfer = WaveSurfer.create({
      container: '#recordedAudioContainer',
      mediaType: 'audio/mp4', // Specify the audio file type,
      backend: 'MediaElement', // isSafari ? 'WebAudio' : 'MediaElement'
      waveColor: '#0e1a2c',
      progressColor: '#EE868C', //red color
      url: recordedUrl,
      // Set a bar width
      barWidth: 2,
      // Optionally, specify the spacing between bars
      barGap: 1,
      // And the bar radius
      barRadius: 2
    })

    // hide the waveFormContainer
    this.waveFormContainerTarget.classList.remove('d-block');
    this.waveFormContainerTarget.classList.add('d-none');

    // show recordedAudioContainer
    this.recordedAudioContainerTarget.classList.remove('d-none');
    this.recordedAudioContainerTarget.classList.add('d-block');

    if (this.answerUrlValue) {
      // we are viewing a previously answered question, need to prep the audio player
      recordedWavesurfer.on('ready', () => {
        // console.log('Ready to play the recorded audio', recordedWavesurfer.getDuration());
        this.updateTotalTime(recordedWavesurfer.getDuration() * 1000);

        if (this.hasLengthContainerTarget) {
          this.lengthContainerTarget.classList.remove('d-none');
          this.lengthContainerTarget.classList.add('d-flex');
        }

        this.recordButtonTarget.innerHTML = "<i class='fas fa-microphone me-1'></i> Record again"
      });
    }

    this.playButtonTarget.removeAttribute('disabled');
    this.playButtonTarget.onclick = () => {
      recordedWavesurfer.playPause();
      this.updatePlaybackProgressTime();
      if(recordedWavesurfer.isPlaying()) {
        // console.log('Now playing, set the interval');
        playbackInterval = setInterval(this.updatePlaybackProgressTime.bind(this), 1000);
      }
    }
    recordedWavesurfer.on('pause', () => {
      this.playButtonTarget.innerHTML = '<i class="fas fa-play-circle fa-lg color-fanword-text"></i>';
      clearInterval(playbackInterval);
      // this.updatePlaybackProgressTime(recordedWavesurfer.getDuration());
    });
    recordedWavesurfer.on('play', () => (this.playButtonTarget.innerHTML = '<i class="fas fa-pause-circle fa-lg color-fanword-text"></i>'))

    // file attached to the hidden input field
    // console.log('Attaching file to audio input field')
    let file = new File([blob], "audio-interview.mp4", {
      lastModified: new Date().getTime(),
    });
    let container = new DataTransfer();
    container.items.add(file);
    this.audioFileTarget.files = container.files;
  }

  updateTotalTime(time) {
    const formattedTime = this.formatTime(time);

    this.totalTarget.textContent = formattedTime;

    this.updateLengthIndicator(time);
  }

  updatePlaybackProgressTime() {
    const timeInSeconds = Math.round(recordedWavesurfer.getCurrentTime()) || 0;

    // console.log('Updating progress time', timeInSeconds);

    const formattedTime = this.formatTime(timeInSeconds * 1000);
    this.progressTarget.textContent = `${formattedTime} /\u00A0`;
  }

  formatTime(timeInMilliSeconds) {
    if (timeInMilliSeconds === 0) {
      return '00:00';
    }

    // time will be in milliseconds, convert it to mm:ss format
    const formattedTime = [
      Math.floor((timeInMilliSeconds % 3600000) / 60000), // minutes
      Math.floor((timeInMilliSeconds % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? '0' + v : v))
      .join(':');

    return formattedTime;
  }

  async record(event) {
    // make sure we have a pristine wavesurfer instance every time we start recording
    this.createWaveSurfer();

    // clear out any interval timer from previous recordings
    clearInterval(playbackInterval);

    // hide the progress time if it was previously shown
    this.progressTarget.classList.remove('d-block');
    this.progressTarget.classList.add('d-none');

    if (event.target.dataset.confirmSwal) {
      // need to confirm first, if we are re-recording
      return;
    }

    // disable buttons that can't be clicked during recording
    this.recordButtonTarget.disabled = true
    this.submitButtonTargets.forEach(elm => elm.disabled = true);
    this.mobileNavigationTargets.forEach(elm => elm.classList.add('pe-none'));

    // hide resume recording button
    this.resumeButtonTarget.classList.remove('d-block');
    this.resumeButtonTarget.classList.add('d-none');

      // get selected device
      recorder.startRecording().then(() => {

        this.answerHasChanged = true;

        // hide the no audio container
        this.noAudioMessageContainerTarget.classList.remove('d-block');
        this.noAudioMessageContainerTarget.classList.add('d-none');

        // hide the preview audio container
        this.recordedAudioContainerTarget.classList.remove('d-block');
        this.recordedAudioContainerTarget.classList.add('d-none');

        // show the waveFormContainer
        this.waveFormContainerTarget.classList.remove('d-none');
        this.waveFormContainerTarget.classList.add('d-block');

        // hide the record button
        this.recordButtonTarget.classList.remove('d-block');
        this.recordButtonTarget.classList.add('d-none');

        // show the pause/stop button
        this.pauseButtonTarget.classList.remove('d-none');
        this.pauseButtonTarget.classList.add('d-block');

        // disable the play button
        this.playButtonTarget.disabled = true;
        this.playButtonTarget.classList.remove('interviewer-red');

        // show the length indicator block
        if (this.hasLengthContainerTarget) {
          this.lengthContainerTarget.classList.remove('d-none');
          this.lengthContainerTarget.classList.add('d-flex');
        }
      }).catch((error) => {
        console.log('Error when starting recording process:', error);
        this.error = error;

        this.contentContainerTarget.classList.remove('d-block');
        this.contentContainerTarget.classList.add('d-none');

        this.errorContainerTarget.classList.remove('d-none');
        this.errorContainerTarget.classList.add('d-block');
      });

  }

  resume() {
    //check if there are any previous recordings and remove them
    // if (this.audioContainerTarget.firstElementChild?.tagName === 'AUDIO') {
    //   this.audioContainerTarget.firstElementChild.remove();
    // }

    // hide the resume button
    this.resumeButtonTarget.classList.remove(('d-block'))
    this.resumeButtonTarget.classList.add(('d-none'))

    // hide the resume button
    this.recordButtonTarget.classList.remove(('d-block'))
    this.recordButtonTarget.classList.add(('d-none'))

    // show the pause button
    this.pauseButtonTarget.classList.remove(('d-none'))
    this.pauseButtonTarget.classList.add(('d-block'))

    // hide the preview audio container
    this.recordedAudioContainerTarget.classList.remove('d-block');
    this.recordedAudioContainerTarget.classList.add('d-none');

    // show the waveFormContainer
    this.waveFormContainerTarget.classList.remove('d-none');
    this.waveFormContainerTarget.classList.add('d-block');

    // hide the progress time
    this.progressTarget.classList.remove('d-block');
    this.progressTarget.classList.add('d-none');

    // disable the submit button
    this.submitButtonTargets.forEach(elm => elm.disabled = true);
    this.mobileNavigationTargets.forEach(elm => elm.classList.add('pe-none'));

    // start recording
    recorder.resumeRecording();

    // this.startInternalRecordingTimer(parseInt(this.durationValue));
  }

  // This method is actually triggered from a button that is labeled "Stop"
  // because that is what users will probably understand it as. But if we
  // used the mediaRecorder.stop() method, it would set the state on the
  // recorder to "inactive" and it couldn't be restarted. So we pause it
  // instead technically, and tell the user they are stopping it.
  async pause() {
    // console.log('Pausing recording');

    //check if there are any previous recordings and remove them
    // if (this.audioContainerTarget.firstElementChild?.tagName === 'AUDIO') {
    //   this.audioContainerTarget.firstElementChild.remove();
    // }

    // pause recording
    recorder.pauseRecording();

    // stop the 1s progress tics
    clearInterval(this.intervalValue);

    // hide the pause button
    this.pauseButtonTarget.classList.remove(('d-block'))
    this.pauseButtonTarget.classList.add(('d-none'))

    // show the resume recording button
    this.resumeButtonTarget.classList.remove(('d-none'))
    this.resumeButtonTarget.classList.add(('d-block'))

    // allow the play button to be clicked
    this.playButtonTarget.removeAttribute('disabled');
    this.playButtonTarget.classList.add('interviewer-red');

    // enable the submit buttons
    this.submitButtonTargets.forEach(elm => elm.disabled = false);
    this.mobileNavigationTargets.forEach(elm => elm.classList.remove('pe-none'));

    // show the re-record button
    this.recordButtonTarget.classList.remove('d-none')
    this.recordButtonTarget.classList.add('d-block')
    this.recordButtonTarget.innerHTML = "<i class='fas fa-microphone me-1'></i> Record again"
    this.recordButtonTarget.removeAttribute('disabled');
    this.recordButtonTarget.dataset.confirmSwal = "Are you sure?"
    this.recordButtonTarget.dataset.text = "Re-recording will replace your previously recorded answer."
  }

  async startRecordVideo() {
    // remove any pre-recorded video
    if (this.hasExistingVideoElementTarget) {
      this.existingVideoElementTarget.remove();
    }

    // disable the submit button
    this.submitButtonTargets.forEach(elm => elm.disabled = true);
    this.mobileNavigationTargets.forEach(elm => elm.classList.add('pe-none'));

    if (stream) {
      stream = undefined;
    }

    stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true }).catch( (error) => {
      alert('Unable to capture your camera. Please check console logs.');
      this.error = error;
      console.error(error);
    });

    // set up the preview of the video stream
    this.videoElementTarget.srcObject = stream;
    this.videoElementTarget.classList.remove('d-none');
    this.videoElementTarget.classList.add('d-block');
    this.videoElementTarget.muted = true;
    this.videoElementTarget.removeAttribute('controls');

    mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"', videoBitsPerSecond: 2500000, audioBitsPerSecond: 128000 });

    this.startVideoRecordingTarget.classList.remove('d-block');
    this.startVideoRecordingTarget.classList.add('d-none');

    this.stopVideoRecordingTarget.classList.remove('d-none');
    this.stopVideoRecordingTarget.classList.add('d-block');

    mediaRecorder.start();

    mediaRecorder.addEventListener('dataavailable', e => {
      // console.log('dataavailable for video chunks');

      const videoURL = window.URL.createObjectURL(e.data);
      this.videoElementTarget.srcObject = null;
      this.videoElementTarget.src = videoURL;

      this.videoElementTarget.setAttribute('controls', true);
      this.videoElementTarget.muted = false;

      this.videoElementTarget.classList.remove('d-none');

      this.stopVideoRecordingTarget.classList.remove('d-block');
      this.stopVideoRecordingTarget.classList.add('d-none');

      this.startVideoRecordingTarget.classList.remove('d-none');
      this.startVideoRecordingTarget.classList.add('d-block');

      this.videoElementTarget.classList.remove('d-none');
      this.videoElementTarget.classList.add('d-block');

      let file = new File([e.data], "video-message.mp4", {
        type: 'video/mp4; codecs="avc1.424028, mp4a.40.2',
        lastModified: new Date().getTime(),
      });
      let container = new DataTransfer();
      container.items.add(file);
      // uploadFile(file);
      this.videoFileTarget.files = container.files;

      this.videoElementTarget.play();

      // re-enable the submit button
      this.submitButtonTargets.forEach(elm => elm.disabled = false);
      this.mobileNavigationTargets.forEach(elm => elm.classList.remove('pe-none'));
    });

  }

  stopRecordVideo() {
    mediaRecorder.stop();

    this.answerHasChanged = true;

    this.startVideoRecordingTarget.innerHTML = "<i class='fas fa-video me-1'></i> Re-record";
  }

  attemptSubmit(event) {
    event.preventDefault();

    // console.log('Audio file', this.audioFileTarget);
    // console.log('Text', this.textTarget);
    // console.log('Video file', this.videoFileTarget);

    let audioPresent = true;
    if (this.hasRecordedAudioContainerTarget) {
      if (this.audioFileTarget.files.length === 0 && this.recordedAudioContainerTarget?.firstElementChild?.tagName !== 'AUDIO') {
        audioPresent = false;
      }
    }

    let textPresent = true;
    if (this.hasTextTarget) {
      if (this.textTarget.value === '') {
        textPresent = false;
      }
    }

    let videoPresent = true;
    if (this.hasVideoFileTarget) {
      if (this.videoFileTarget.files.length === 0) {
        videoPresent = false;
      }
    }

    if(!audioPresent && !textPresent && !videoPresent) {
      createAlert('info', 'Please record an answer before moving forward.');

      const modal = bootstrap.Modal.getOrCreateInstance(this.savingModalTarget);
      modal.hide();

      return;
    }

    if (this.hasRecordedAudioContainerTarget) {

      if (this.answerHasChanged) {
        document.querySelector('#form_changed').value = 'true';
      } else {
        // if the answer has not changed, we can submit the form right away
        document.querySelector('#form_changed').value = 'false';
        event.target.form.submit();
        return;
      }

      // For audio, we need to stop the audio player, receive the FULL blob of data, and append that to the form
      if (audioPresent) {
        this.stopRecordingAndAttachFile().then(() => {
          // Submit the form once the file is attached
          event.target.form.submit();
        }).catch((error) => {
          console.error("Error stopping recording or attaching file:", error);
        });
      } else {
        // For text and video, we can submit right away
        event.target.form.submit();
      }

    } else {

      if (this.answerHasChanged) {
        document.querySelector('#form_changed').value = 'true';
      }

      event.target.form.submit();
    }

  }

  stopRecordingAndAttachFile() {
    // console.log('Stopping recording and attaching file, returning promise');

    return new Promise((resolve, reject) => {

      // Listen for the recording stop event
      recorder.on("record-end", (blob) => {
        if (!blob) {
          reject(new Error("No audio blob generated."));
          return;
        }

        // console.log("Recording stopped, got the blob", blob);

        // Create and attach the file
        let file = new File([blob], "audio-interview.mp4", {
          lastModified: new Date().getTime(),
        });
        let container = new DataTransfer();
        container.items.add(file);
        this.audioFileTarget.files = container.files;

        // console.log("Audio file attached to input field");

        // Resolve the promise to continue submission
        resolve();
      });

      // Stop the recording
      recorder.stopRecording();
    });
  }

  sendBugReport() {
    const bugReport = {
      origin: window.location.href,
      error: this.error.toString(),
      cookiesEnabled: navigator.cookieEnabled,
      language: navigator.language,
      userAgent: navigator.userAgent,
      navigatorUAData: navigator.userAgentData,
      dimensions: { width: window.innerWidth, height: window.innerHeight }
    };

    fetch(this.bugReportPathValue, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bugReport)
    });

    createAlert('info', 'Bug Report Sent!');
  }

  updateLengthIndicator(duration) {
    // console.log('Updating length indicator with duration:', duration);
    // console.log('Target length:', this.questionTargetLengthValue);

    var good, better, best;
    switch (this.questionTargetLengthValue) {
      case 'short':
        good = 5;
        better = 10;
        best = 15;
        break;

      case 'medium':
        good = 15;
        better = 30;
        best = 60;
        break;

      case 'long':
        good = 30;
        better = 60;
        best = 120;
        break;

      default:
        good = 15;
        better = 30;
        best = 60;
        break;
    }

    duration = duration / 1000; // comes in miliseconds

    let icon, color, message;

    // determine level of progress
    if (duration < good) {
      icon = ["fa-regular", "fa-face-frown"];
      color = 'bg-fanword-negative'
      message = 'Needs to be longer';
    } else if(duration >= good && duration < better) {
      icon = ["fa-regular", "fa-face-meh"];
      color = 'bg-fanword-lightyellow'
      message = 'Getting there...';
    } else if(duration >= better && duration < best) {
      icon = ["fa-regular", "fa-face-smile"];
      color = 'bg-fanword-positive'
      message = 'Good answer!';
    } else if(duration >= best) {
      icon = ["fa-regular", "fa-face-grin-stars"];
      color = 'bg-fanword-yellow'
      message = 'GREAT answer!';
    }

    if (this.hasLengthIconTarget) {
      this.lengthIconTarget.classList.remove('bg-fanword-negative', 'bg-fanword-lightyellow', 'bg-fanword-positive', 'bg-fanword-yellow');
      this.lengthIconTarget.classList.add(color);

      const smiley = this.lengthIconTarget.querySelector('#smiley');
      smiley.classList.remove('fa-regular', 'fa-face-frown', 'fa-face-meh', 'fa-face-smile', 'fa-face-grin-stars');
      icon.forEach((i) => smiley.classList.add(i));

      this.lengthTextTarget.innerText = message;
    }
  }

  questionPlayAudio() {
    this.questionPlayTarget.classList.remove('d-block');
    this.questionPlayTarget.classList.add('d-none');

    this.questionPauseTarget.classList.remove('d-none');
    this.questionPauseTarget.classList.add('d-block');

    this.questionAudioTarget.addEventListener('ended', () => {
      this.questionPauseTarget.classList.remove('d-block');
      this.questionPauseTarget.classList.add('d-none');

      this.questionPlayTarget.classList.remove('d-none');
      this.questionPlayTarget.classList.add('d-block');
    });

    this.questionAudioTarget.play();
  }

  questionPauseAudio() {
    this.questionPauseTarget.classList.remove('d-block');
    this.questionPauseTarget.classList.add('d-none');

    this.questionPlayTarget.classList.remove('d-none');
    this.questionPlayTarget.classList.add('d-block');

    this.questionAudioTarget.pause();
  }

  selectImage() {
    this.imageInputTarget.click();
  }

  submitImage() {
    if (this.imageInputTarget.files.length === 0) {
      createAlert('info', 'Please select an image before submitting.');
      return;
    }

    const modal = new bootstrap.Modal(this.savingModalTarget);
    modal.show();

    this.imageInputTarget.form.submit();
  }

}
