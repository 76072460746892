// app/javascript/controllers/combined_recaps_controller.js
import { createAlert } from '../scripts/createAlert';
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submit", "tooltipWrapper"];

  tooltip; // Tooltip instance

  connect() {
    // console.log('Hello from the combined recaps controller', this.element);
    // console.log('Checkboxes: ', this.checkboxTargets);
    // console.log('Submit button: ', this.submitTarget);

    this.tooltip = new bootstrap.Tooltip(this.tooltipWrapperTarget);
  }

  submit(event) {
    if (this.checkboxTargets.filter(e => e.checked).length < 2) {
      event.preventDefault();
      createAlert('info', 'Please select at least two recaps to combine.');
      return;
    }

    const url = new URL(document.location);
    const params = url.searchParams;

    params.set('tab', 'combined_recaps');
    this.checkboxTargets.filter(e => e.checked).forEach((checkbox) => {
      params.append('recap_ids[]', checkbox.value);
    });

    window.location.href = url.toString();
  }

  toggleCheckbox(event) {
    if (this.checkboxTargets.filter(e => e.checked).length >= 2) {
      this.submitTarget.removeAttribute('disabled');
      this.tooltip.disable();
    } else {
      this.submitTarget.setAttribute('disabled', 'disabled');
      this.tooltip.enable();
    }
  }

}