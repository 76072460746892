import { Controller } from "@hotwired/stimulus"
import { Loader } from '@googlemaps/js-api-loader';

export default class extends Controller {
  static targets = [ 'input' ]
  static values = {
    key: String
  }

  connect() {
    // console.log("Hello, Google Maps Controller here!", this.element);
    // console.log(this.pageTargets);

    const loader = new Loader({
      apiKey: this.keyValue,
      version: "weekly",
      libraries: ["places"]
    });

    const options = {
      fields: ["formatted_address", "address_components"],
      strictBounds: false,
      types: ["(cities)"],
    };

    loader.load().then((google) => {

      this.inputTargets.forEach(elm => {
        const autocomplete = new google.maps.places.Autocomplete(elm, options);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          // console.log('PLACE: ', place);
          elm.value = place.formatted_address;
        });
      })

    }).catch(e => {
      console.log('Problem initializing Google Maps')
    });

  }

}
