import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { createAlert } from '../scripts/createAlert';

export default class extends Controller {
  static targets = [ 'sortable', 'block', 'input', 'placeholder' ]
  static values = { url: String }

  connect() {
    // console.log("Hello, Game Recap Layout Controller here!", this.element);

    const that = this;

    /* Sortable */
    this.sortableTargets.forEach(elm => {
      Sortable.create(elm, {
        draggable: '.layout-block',
        dataIdAttr: 'data-sortable-id',
        handle: ".sortable-handle",  // Drag handle selector within list items

        // Changed sorting within list
        onUpdate: (event) => {
         that.updateNewOrder();
        }
      });
    });

    this.inputTargets.forEach(elm => elm.addEventListener('click', this.toggleBlock.bind(this)));
  }

  toggleBlock(event) {
    // console.log('Updating activation and order');

    const checkboxInput = event.target;
    const isBeingAdded = checkboxInput.checked;

    // this.element is the checkbox, need to find the block
    const block = checkboxInput.closest('.layout-block');

    // was this an active block that was checked, or an available block
    const activeWasChecked = block.dataset.active === 'true';

    if (activeWasChecked) {
      // remove it from the this.blocksTargets
      block.dataset.gameRecapLayoutTarget = '';

      // find the avaiable block with this slug and uncheck it
      const blockSlug = block.dataset.sortableId;
      this.inputTargets.find(elm => elm.value === blockSlug).checked = false;

      // remove it from the active side
      block.remove();

    } else {

      // if the checkbox was checked, we are adding, if it was unchecked, we are removing  the active block
      if (isBeingAdded) {
        // clone it
        const clone = block.cloneNode(true);

        // cloning doensn't include event listeners, so we have to manually add it back
        clone.querySelector('input[type="checkbox"]').addEventListener('click', this.toggleBlock.bind(this));

        // add it to the this.blocksTargets
        clone.dataset.gameRecapLayoutTarget = 'block';
        clone.dataset.active = 'true';

        // enable the drag handle
        clone.querySelector('.sortable-handle').classList.remove('d-none');
        clone.querySelector('.sortable-handle').classList.add('d-flex');

        // add it to the active side
        this.sortableTarget.appendChild(clone);
      } else {
        // remove it from the this.blocksTargets, and remove it from the DOM
        const activeBlock = this.blockTargets.find(elm => elm.dataset.sortableId === block.dataset.sortableId)
        activeBlock.dataset.recapLayoutTarget = '';
        activeBlock.remove();
      }

    }

    this.updateNewOrder();
  }

  updateNewOrder() {
    // update the new layout
    const blocks = this.blockTargets;
    const sortableIds = blocks.map(elm => elm.dataset.sortableId)

    if (this.hasPlaceholderTarget) {
      if (sortableIds.length === 0) {
        this.placeholderTarget.classList.remove('d-none');
        this.placeholderTarget.classList.add('d-flex');
      } else {
        this.placeholderTarget.classList.remove('d-flex');
        this.placeholderTarget.classList.add('d-none');
      }
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    var token;
    if (document.querySelector('meta[name="csrf-token"]')) {
      token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      headers['X-CSRF-Token'] = token;
    }

    fetch(this.urlValue, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ rails_sortable: sortableIds })
    }).then( () => {
      createAlert('success', 'Layout updated successfully');
    }).catch( (error) => {
      createAlert('error', 'There was an error updating the layout');
    });
  }


}
