import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    param: String
  }

  connect() {
    // console.log("Hello, from tabs stimulus controller!", this.element)
    // console.log(this.paramValue);

    this.element.addEventListener('shown.bs.tab', (event) => {

      // update the query parameter in the url on navigation
      const activeTab = event.target || this.getActiveTab();
      if (!activeTab) {
        return;
      }

      const bsTarget = activeTab.dataset.bsTarget;
      if (!bsTarget) {
        return;
      }

      var tab = bsTarget.replace('#', '');

      // Construct URLSearchParams object instance from current URL querystring.
      var queryParams = new URLSearchParams(window.location.search);

      // Set new or modify existing parameter value.
      const tabParam = this.paramValue || 'tab';
      queryParams.set(tabParam, tab);

      // Replace current querystring with the new one.
      history.replaceState(null, null, "?"+queryParams.toString());

      // if any element inside of this tab is marked as needing to load content, reload content
      if (document.querySelectorAll(`${event.target.dataset.bsTarget} *[reloadcontent="true"]`).length > 0) {
        window.location.reload();
      }

      // propigate the event to any child tabs who also need to update their query parameters
      document.querySelectorAll(`${event.target.dataset.bsTarget} .active[data-bs-toggle="tab"]`).forEach((tabElement) => {
        const tabInstance = bootstrap.Tab.getOrCreateInstance(tabElement);
        tabInstance.show()

        // Manually dispatch the event since Bootstrap won't trigger it if the tab is already active
        const shownEvent = new Event("shown.bs.tab", { bubbles: true, cancelable: true });
        tabElement.dispatchEvent(shownEvent);
      });

    })
  }

  getActiveTab() {
    return this.element.querySelector('[data-bs-toggle="tab"].active');
  }

}
