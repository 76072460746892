import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "note", "notesContainer" ]
  static values = {
    seasonId: Number,
    seasonSlug: String,
    url: String
  }

  connect() {
    // console.log("Hello, Stimulus from Player Note controller!", this.element)
    // console.log(this.noteContainerTarget)
    // console.log(this.noteTargets)
  }

  addNote() {
    fetch(this.urlValue).then(res => res.text()).then(partial => {
      partial = partial.replace(/NEW_ID/g, this.noteTargets.length + 1)
      partial = partial.replace(/SEASON_ID/g, this.seasonIdValue)
      partial = partial.replace(/SEASON_SLUG/g, this.seasonSlugValue)

      this.notesContainerTarget.insertAdjacentHTML( 'beforeend', partial );
    });
  }
}

