import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "playButton", "videoElement" ]

  connect() {
    // console.log("Hello, Video Player Controller here!", this.element)
    // console.log(this.modalTarget);
    // console.log(this.playButtonTarget);
    // console.log(this.videoElementTarget);

    // const modal = new bootstrap.Modal(this.modalTarget);

    this.modalTarget.addEventListener('shown.bs.modal', () => {
      this.videoElementTarget.play();
    });

    this.modalTarget.addEventListener('hidden.bs.modal', () => {
      this.videoElementTarget.pause();
    });
  }

}
