import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'input', 'form' ]

  connect() {
    // console.log("Hello, Directory Search Controller here!", this.element);
    // console.log(this.inputTarget);
    // console.log(this.formTarget);
  }

  toggleSearch(event) {
    event.preventDefault();
    if (this.inputTarget.classList.contains('d-sm-none') || this.inputTarget.classList.contains('d-lg-none')) {
      this.inputTarget.classList.remove('d-sm-none');
      this.inputTarget.classList.remove('d-lg-none');
      this.inputTarget.focus();
    } else {
      this.inputTarget.classList.add('d-sm-none');
      this.inputTarget.classList.add('d-lg-none');
    }
  }

  checkForSubmit(event) {
    if (event.code === 13 && event.target.value.length > 0) {
      this.formTarget.trigger('submit');
    }
  }
}
