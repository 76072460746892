import { Controller } from "@hotwired/stimulus";
import CookiesEuBanner from 'cookies-eu-banner'

export default class extends Controller {
  static targets = [ 'banner' ];

  connect() {
    // console.log('Hello from the EU Cookue Banner!', this.element);
    // console.log(this.bannerTarget);

    new CookiesEuBanner(() => {
      // console.log('Cookies EU Banner accepted');

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', window.GOOGLE_ANALYTICS);
    }, false)
  }

}