// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
var jQuery = require('jquery');
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

const bootstrap = require('bootstrap');
global.boostrap = bootstrap;
window.bootstrap = bootstrap;

const cropper = require('cropperjs');
global.Cropper = cropper;
window.Cropper = cropper;

import Swal from 'sweetalert2/dist/sweetalert2.min.js'
window.Swal = Swal;

// TODO this would maybe speed things up a bit in the future if we wanted to
// try and figure out how to get it to work
import "@hotwired/turbo-rails"
Turbo.setFormMode("optin");

import '@fortawesome/fontawesome-free/js/all';
import 'chartkick/chart.js';

/* Stimulus Setup */
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);

// Configure Stimulus development experience
application.debug = true;
window.Stimulus   = application;
Stimulus.load(definitionsFromContext(context));

import LocalTime from 'local-time';
LocalTime.start()

// Importing Quill Styles to be used in WYSIWYG layout
import "quill/dist/quill.core.css";

// Rails unobtruisive javascript
import Rails from '@rails/ujs';
Rails.start();
