// app/javascript/controllers/bios_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submit", "players"];

  connect() {
    // console.log('Hello from the bios controller', this.element);
    // console.log('Checkboxes: ', this.checkboxTargets);
    // console.log('Submit button: ', this.submitTarget);
  }

  togglePlayer(event) {
    const checkedPlayers = this.checkboxTargets.filter((checkbox) => checkbox.checked && !checkbox.disabled).map((checkbox) => parseInt(checkbox.value));
    this.playersTarget.value = JSON.stringify(checkedPlayers);
    if (checkedPlayers.length > 0) {
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.submitTarget.setAttribute('disabled', true);
    }
  }

  toggleAllPlayers(event) {
    if (event.target.checked === false) {
      this.checkboxTargets.filter((checkbox) => !checkbox.disabled).forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.playersTarget.value = JSON.stringify([]);
      this.submitTarget.setAttribute('disabled', true);
    } else {
      this.checkboxTargets.filter((checkbox) => !checkbox.disabled).forEach((checkbox) => {
        checkbox.checked = true;
      });
      this.playersTarget.value = JSON.stringify(this.checkboxTargets.filter((checkbox) => !checkbox.disabled).map((checkbox) => parseInt(checkbox.value)));
      this.submitTarget.removeAttribute('disabled');
    }
  }

}