import GLightbox from 'glightbox';

class FanwordLightbox {
  static getLightbox(selector) {
    if (!this[selector]) {
      this[selector] = GLightbox({ selector: `.${selector}`, touchNavigation: true, zoomable:true })
    }

    return this[selector];
  }

  static reloadLightbox(selector) {
    const lightbox = this.getLightbox(selector);
    lightbox.reload();
  }
}


export default FanwordLightbox;
