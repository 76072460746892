import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'toggle' ]

  fullScreen = false;

  connect() {
    console.log("Hello, FTP Controller here!", this.element)

    const storedValue = JSON.parse(localStorage.getItem('ftp-fullscreen'));

    console.log('Stored Value is: ', storedValue);
    if (storedValue === true) {
      this.makeFullScreen();
    }

  }

  makeFullScreen() {
    console.log('makeFullScreen');

    const sidebar = document.querySelector('#admin-sidebar-container');
    const content = document.querySelector('#admin-content-container');

    sidebar.classList.toggle('d-none');
    content.classList.toggle('col-md-12');

    this.toggleTarget.classList.toggle('d-none');

    this.fullScreen = true;

    localStorage.setItem('ftp-fullscreen', true);
  }

  makeNotFullScreen() {
    console.log('shrinking screen');

    const sidebar = document.querySelector('#admin-sidebar-container');
    const content = document.querySelector('#admin-content-container');

    sidebar.classList.toggle('d-none');
    content.classList.toggle('col-md-12');

    this.toggleTarget.classList.toggle('d-none');

    this.fullScreen = false;

    localStorage.setItem('ftp-fullscreen', false);
  }

}
