import { Controller } from "@hotwired/stimulus"
import Shepherd from 'shepherd.js';

export default class extends Controller {
  static targets = [ "sortable" ]
  static values = {
    fanwordLogo: String
  }

  connect() {
    // console.log("Hello, Tour Controller here!", this.element)

    document.addEventListener('DOMContentLoaded', () => {
      // The tour is triggered by the existance of the `tour` query param
      // which is removed at the end of the tour
      const query = new URLSearchParams(window.location.search);
      if (query.get('tour') === 'true') {
        this.prepareTour();
      }
    })
  }

  async prepareTour() {
    const isMobile = window.innerWidth <= 768;
    const stepTwoElement = isMobile ? '#mobile-add-dropdown' : '#desktop-add-dropdown'

    const addDropdownListElement = document.getElementById(isMobile ? 'mobile-add-dropdown' : 'desktop-add-dropdown');

    const offcanvasElement = document.getElementById('mobile-team-collapsed-menu');
    const mobileMenuOffcanvas = new bootstrap.Offcanvas(offcanvasElement, { toggle: false });

    // TODO this would be a great blog post
    const tourOneElements = document.getElementsByClassName('tourone');
    const tourOneElement = Array.from(tourOneElements).find(elm => window.getComputedStyle(elm).display === 'block')

    let tourFourNavbar, tourFourElement;
    if (isMobile) {
      tourFourNavbar = document.querySelector(`#${ isMobile ? 'mobile' : 'desktop' }-team-collapsed-menu`);
      tourFourElement = tourFourNavbar.getElementsByClassName(`${ isMobile ? 'mobile' : 'desktop' }-tourfour`)[0];
    } else {
      tourFourElement = document.getElementsByClassName(`${ isMobile ? 'mobile' : 'desktop' }-tourfour`)[0];
    }

    var tourFiveElement = document.getElementsByClassName(`${ isMobile ? 'mobile' : 'desktop' }-tourfive`)[0];
    if (!tourFiveElement) {
      tourFiveElement = document.querySelector('.navbar-nav.navbar-nav-scroll');
    }

    let firstName = document.querySelector('#athlete-name').dataset.firstName;

    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      exitOnEsc: true,
      keyboardNavigation: true,
      defaultStepOptions: {
        classes: 'fanword-shepherd-tour',
        modalOverlayOpeningPadding: 10,
        scrollTo: false,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }]
        }
      }
    });

    ['close', 'cancel', 'complete'].forEach(event => tour.on(event, () => {
      history.replaceState(null, '', window.location.pathname);
      mobileMenuOffcanvas.hide();
    }));

    tour.addSteps([
      {
        id: 'tour-step-zero',
        text: `
          <div class='bg-fanword-yellow d-flex flex-column justify-content-center align-items-center' style='min-height: 200px;'>
            <div class='bg-fanword-white my-5 d-flex flex-direction-column justify-content-center align-items-center' style='width: 100px; height: 100px; border-radius: 100px;'>
              <img src='${this.fanwordLogoValue}' style='width: 50%; display: block; margin: 0 auto;' />
            </div>
          </div>
          <div class='p-5'>
            <h2 class='mb-5 text-center'>Hi, ${firstName}!</h2>
            <p class='text-muted font-fanword-regular text-center lh-base'>On behalf of the entire FanWord team, welcome to the first and only student-athlete directory in the country. Let us show you how you can maximize your profile and market yourself to businesses and fans. Ready?
            <div class='text-center'>
              <button class='btn-sm border-0 bg-fanword-yellow px-5 py-3 mb-3 color-fanword-black' type='button' id='tour-lets-go'>
                Let's Go!
              </button>
              <br>
              <a class='color-fanword-text font-fanword-regular text-decoration-none' href='#' id='tour-skip'>No, thanks! I'll explore on my own.</a>
            </div>
          </div>
        `,
        when: {
          show: () => {
            const skipButton = document.querySelector('#tour-skip')
            skipButton.addEventListener('click', function (event) {
              event.preventDefault();
              tour.complete()
            })

            const goButton = document.querySelector('#tour-lets-go')
            goButton.addEventListener('click', function (event) {
              tour.next()
            })
          }
        }
      },
      {
        id: 'tour-step-one',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              Your FanWord profile is like your own website that allows you to list everything about you in one single place. Show businesses and fans all of the different ways they can support, work & connect with you. To add content to your profile, simply click the '+' icon to get started.
            </p>
          </div>`,
        attachTo: {
          element: tourOneElement,
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Finish',
            action: tour.complete,
            classes: 'btn-sm border-0 px-3 py-2 text-muted text-decoration-none'
          },
          {
            text: '<i class="fas fa-angle-left fs-5 color-fanword-text"></i>',
            action: tour.back,
            classes: 'btn-sm border-0 bg-fanword-background px-3 py-2 text-decoration-none'
          },
          {
            text: '<i class="fas fa-angle-right fs-5 color-fanword-text color-fanword-black"></i>',
            action: tour.next,
            classes: 'btn-sm border-0 bg-fanword-yellow px-3 py-2 text-decoration-none'
          }
        ],
        when: {
          show: () => {
            const currentStepElement = tour.currentStep.el;
            const shepherdText = currentStepElement.querySelector('.shepherd-footer');
            const progress = document.createElement('span');
            progress.classList = 'color-fanword-text ms-3 position-absolute';
            progress.style.left = '10px';
            progress.innerText = `${tour.steps.indexOf(tour.currentStep)}/${tour.steps.length - 1}`;
            shepherdText.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
          }
        }
      },
      {
        id: 'tour-step-two',
        text: `
          <div class='p-4'>
            <p class='font-fanword-regular'>Every item on your profile is fully customizable. Click any of the following areas to get started.</p>
            <p class='font-fanword-regular'>Edit Profile = add your location, awards, biography, and more.</p>
            <p class='font-fanword-regular'>Edit Links = link to your social media profiles, marketplaces, and more.</p>
            <p class='font-fanword-regular'>Edit Media = upload photos, videos, and other media to your profile.</p>
          </div>
        `,
        attachTo: {
          element: stepTwoElement,
          on: 'bottom'
        },
        beforeShowPromise: () => {
          return new Promise(resolve => {
            addDropdownListElement.classList.add('show');
            return resolve(true)
          })
        },
        when: {
          show: () => {
            const currentStepElement = tour.currentStep.el;
            const shepherdText = currentStepElement.querySelector('.shepherd-footer');
            const progress = document.createElement('span');
            progress.classList = 'color-fanword-text ms-3 position-absolute';
            progress.style.left = '10px';
            progress.innerText = `${tour.steps.indexOf(tour.currentStep)}/${tour.steps.length - 1}`;
            shepherdText.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
          },
          hide: () => {
            return new Promise(resolve => {
              addDropdownListElement.classList.remove('show');
              return resolve(true)
            })
          }
        },
        buttons: [
          {
            text: 'Finish',
            action: tour.complete,
            classes: 'btn-sm border-0 px-3 py-2 text-muted text-decoration-none'
          },
          {
            text: '<i class="fas fa-angle-left fs-5 color-fanword-text"></i>',
            action: tour.back,
            classes: 'btn-sm border-0 bg-fanword-background px-3 py-2 text-decoration-none'
          },
          {
            text: '<i class="fas fa-angle-right fs-5 color-fanword-text color-fanword-black"></i>',
            action: tour.next,
            classes: 'btn-sm border-0 bg-fanword-yellow px-3 py-2 text-decoration-none'
          }
        ]
      },
      {
        id: 'tour-step-three',
        text: `
          <div class='p-4'>
            <p class='font-fanword-regular'>
              To get the most out of your FanWord profile, you want to market it to your audience. The easiest way to do that is to simply copy your profile link and add it to your social media bios on Instagram, X, TikTok, etc.
            </p>
          </div>
        `,
        attachTo: {
          element: '.tourthree',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        when: {
          show: () => {
            const currentStepElement = tour.currentStep.el;
            const shepherdText = currentStepElement.querySelector('.shepherd-footer');
            const progress = document.createElement('span');
            progress.classList = 'color-fanword-text ms-3 position-absolute';
            progress.style.left = '10px';
            progress.innerText = `${tour.steps.indexOf(tour.currentStep)}/${tour.steps.length - 1}`;
            shepherdText.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
          }
        },
        buttons: [
          {
            text: 'Finish',
            action: tour.complete,
            classes: 'btn-sm border-0 px-3 py-2 text-muted text-decoration-none'
          },
          {
            text: '<i class="fas fa-angle-left fs-5 color-fanword-text"></i>',
            action: tour.back,
            classes: 'btn-sm border-0 bg-fanword-background px-3 py-2 text-decoration-none'
          },
          {
            text: '<i class="fas fa-angle-right fs-5 color-fanword-text color-fanword-black"></i>',
            action: tour.next,
            classes: 'btn-sm border-0 bg-fanword-yellow px-3 py-2 text-decoration-none'
          }
        ]
      },
      {
        id: 'tour-step-four',
        text: `
          <div class='p-4'>
            <p class='font-fanword-regular'>
              Click on your name to access your profile, account settings, and premium features if they are unlocked for you.
            </p>
          </div>
        `,
        attachTo: {
          element: tourFourElement,
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        beforeShowPromise: () => {
          return new Promise(resolve => {

            if (!isMobile) {
              // console.log('We are not mobile no need to offcanvas things')
              return resolve()
            }

            offcanvasElement.addEventListener('shown.bs.offcanvas', function (event) {
              // console.log('Got to mobile menu open shown event')
              return resolve(true)
            })

            mobileMenuOffcanvas.show()
            // console.log('Offcanvas shown');

            // since this collapse doesn't show/hide each step on mobile, we need to check to see if its already open
            // if (mobileMenuOffcanvas.classList.contains('show')) {
            //   return resolve(true);
            // }

          })
        },
        when: {
          show: () => {
            const currentStepElement = tour.currentStep.el;
            const shepherdText = currentStepElement.querySelector('.shepherd-footer');
            const progress = document.createElement('span');
            progress.classList = 'color-fanword-text ms-3 position-absolute';
            progress.style.left = '10px';
            progress.innerText = `${tour.steps.indexOf(tour.currentStep)}/${tour.steps.length - 1}`;
            shepherdText.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
          },
          hide: () => {
            return new Promise(resolve => {
              mobileMenuOffcanvas.hide();
              return resolve(true)
            })
          }
        },
        buttons: [
          {
            text: 'Finish',
            action: tour.complete,
            classes: 'btn-sm border-0 px-3 py-2 text-muted text-decoration-none'
          },
          {
            text: '<i class="fas fa-angle-left fs-5 color-fanword-text"></i>',
            action: tour.back,
            classes: 'btn-sm border-0 bg-fanword-background px-3 py-2 text-decoration-none'
          },
          {
            text: '<i class="fas fa-angle-right fs-5 color-fanword-text color-fanword-black"></i>',
            action: tour.next,
            classes: 'btn-sm border-0 bg-fanword-yellow px-3 py-2 text-decoration-none'
          }
        ]
      },
      {
        id: 'tour-step-five',
        text: `
          <div class='p-4'>
            <p class='font-fanword-regular'>
              Use this menu to explore profiles from other teams or sports. Check out what other athletes are doing to get inspiration for your own profile.
            </p>
          </div>
        `,
        attachTo: {
          element: tourFiveElement,
          on: 'bottom'
        },
        when: {
          show: () => {
            const currentStepElement = tour.currentStep.el;
            const shepherdText = currentStepElement.querySelector('.shepherd-footer');
            const progress = document.createElement('span');
            progress.classList = 'color-fanword-text ms-3 position-absolute';
            progress.style.left = '10px';
            progress.innerText = `${tour.steps.indexOf(tour.currentStep)}/${tour.steps.length - 1}`;
            shepherdText.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
          }
        },
        buttons: [
          {
            text: '<i class="fas fa-angle-left fs-5 color-fanword-text"></i>',
            action: tour.back,
            classes: 'btn-sm border-0 bg-fanword-background px-3 py-2 text-decoration-none'
          },
          {
            text: 'Done',
            action: tour.complete,
            classes: 'btn-sm border-0 bg-fanword-yellow px-3 py-2 color-fanword-black text-decoration-none'
          }
        ]
      }

    ])

    tour.start();
  }

}
