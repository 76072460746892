import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { createAlert } from '../scripts/createAlert';

export default class extends Controller {
  static targets = [ "container", "saveButton", "data", "sortable" ]
  static values = {
    article: Number
  }

  connect() {
    // console.log("Hello, Articles Controller here!", this.element)
    // console.log('container', this.containerTarget)
    // console.log('save button', this.saveButtonTarget)
    // console.log('data', this.dataTargets)

    this.sortableTargets.forEach(elm => {
      Sortable.create(elm, {
        draggable: '.data-sortable',
        dataIdAttr: 'data-sortable-id',
        handle: ".sortable-handle",  // Drag handle selector within list items

        // Changed sorting within list
        onUpdate: (event) => {
          const rows = event.target.querySelectorAll('.row.data-sortable');
          const attachmentIds = Array.from(rows).map(elm => elm.dataset.attachmentId)

          const token = $('meta[name="csrf-token"]').attr('content');
          $.ajaxSetup({
            beforeSend: function (xhr) {
              xhr.setRequestHeader('X-CSRF-Token', token);
            }
          });

          $.post(`/articles/${this.articleValue}/data/reorder`, { ordered_data_ids: attachmentIds }).then(() => {
            createAlert('success', 'Data order updated')
          })
        }
      });
    })
  }

  toggleData(event) {
    // console.log('Event triggered', event.target);

    if (event.target.classList.contains('submenu-button-container')) {
      return;
    }

    const toggleButton = event.target.nextElementSibling;
    toggleButton && toggleButton.click();
  }

  addData(event) {
    if (event.target.classList.contains('disabled')) {
      return;
    }

    fetch(`/articles/${this.articleValue}/data/new`).then(res => res.text()).then(partial => {
      this.containerTarget.insertAdjacentHTML( 'beforeend', partial );

      // add focus to newly created input
      this.dataTargets[this.dataTargets.length - 1].querySelector('input').focus();

      if (this.dataTargets.length >= 3) {
        this.saveButtonTarget.removeAttribute('disabled');
      }
    });
  }

  cleanPastedContent(event) {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the clipboard data
    let pasteData = (event.clipboardData || window.clipboardData).getData('text');

    // use the DOM parser to remove HTML for us
    let doc = new DOMParser().parseFromString(pasteData, 'text/html');
    pasteData = doc.body.textContent || "";

    // Modify the pasted content, e.g., trimming and uppercasing
    // pasteData = pasteData.trim().toUpperCase();  // Example modification

    // Insert the modified content using execCommand to preserve undo functionality
    // Yes, I know it deprecated, but according to my overlord ChatGPT its still the best way
    // to preserve undo functionality.
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, pasteData);
    } else {
      // Fallback for browsers that do not support execCommand('insertText')
      const start = event.target.selectionStart;
      const end = event.target.selectionEnd;
      const currentValue = event.target.value;
      event.target.value = currentValue.slice(0, start) + pasteData + currentValue.slice(end);
      event.target.setSelectionRange(start + pasteData.length, start + pasteData.length);
    }
  }
}
